import React, { useState } from 'react';

import {useTranslation} from "react-i18next"
import { Form } from 'react-bootstrap';
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import SEO from "../components/Seo/Seo"
import "../styles/pages/pricing.scss"
import {getSignUpLink} from "../i18n";
import LocalizedLink from "../components/LocalizedLink/LocalizedLink";


const PricingPage = ({ location }) => {
  const { i18n, t } = useTranslation();
  const [isSwitchOn, setIsSwitchOn] = useState(true);

  const onSwitchAction = () => {
    setIsSwitchOn(!isSwitchOn);
  };

  return (
    <>
      <SEO
        title={t("navigation.pricing")}
        lang={i18n.language}
        description={t("meta.pricing.description")}
        pathname={location.pathname}
      />
      <div className="Pricing">
        <section>
          <div className="container text-center">
            <h1 className="text-white">{t("pricingPage.title")}</h1>
            <p className="text-white">{t("pricingPage.subtitle")}</p>
          </div>
        </section>

        <div className="position-relative">
          <div className="custom-shape-divider-bottom">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path
                  d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                  fill="#fff"></path>
            </svg>
          </div>
        </div>

        <section className="bg-white pt-5 py-4 position-relative">
          <div className="container">
            <div className="text-center">
              {t("pricingPage.monthly")}
              <Form.Switch
                  className="d-inline-block ml-2"
                  onChange={onSwitchAction}
                  id="custom-switch"
                  label=""
                  checked={isSwitchOn}
              />
              {t("pricingPage.annually")}<span className="text-success position-absolute ml-1">({t("pricingPage.save")} 20%)</span>
            </div>
            <ul className="row pricing-table list-unstyled m-0 pb-md-4 pt-5 text-center">
              <li className="offset-md-2 col-md-4">
                <div className="pricing-container">
                  <h3>Starter</h3>
                  <div className="price d-flex align-items-center justify-content-center">
                    <div className="price-figure">
                      <span className="price-number">€{isSwitchOn ? (<span>2.40</span>) : ('3')}</span>
                      <span className="price-tenure">{t("pricingPage.perActiveUser")} / {t("pricingPage.perMonth")}
                        {i18n.language === 'sk' && <span><br />({t("pricingPage.exclVat")})</span>}
                      </span>
                    </div>
                  </div>
                  <ul className="features list-unstyled">
                    <li><FontAwesomeIcon icon={faCheck} fixedWidth width="14" />{t("pricingPage.features.employeeScheduling")}</li>
                    <li><FontAwesomeIcon icon={faCheck} fixedWidth width="14" />{t("pricingPage.features.employeeAvailability")}</li>
                    <li><FontAwesomeIcon icon={faCheck} fixedWidth width="14" />{t("pricingPage.features.shiftTemplates")}</li>
                    <li><FontAwesomeIcon icon={faCheck} fixedWidth width="14" />{t("pricingPage.features.laborCost")}</li>
                    <li><FontAwesomeIcon icon={faCheck} fixedWidth width="14" />{t("pricingPage.features.payrollReport")}</li>
                    <li><FontAwesomeIcon icon={faCheck} fixedWidth width="14" />{t("pricingPage.features.notifications")}</li>
                    <li><FontAwesomeIcon icon={faCheck} fixedWidth width="14" />{t("pricingPage.features.mobileApp")}</li>
                    <li><FontAwesomeIcon icon={faCheck} fixedWidth width="14" />{t("pricingPage.features.activityLogs")}</li>
                  </ul>
                  <div className="footer">
                    <a href={getSignUpLink(i18n.language)} className="btn btn-success">
                      {t("pricingPage.signUpButton")}
                    </a>
                  </div>
                </div>
              </li>
              {/*<li className="col-md-3 highlight">*/}
              {/*  <div className="pricing-container">*/}
              {/*    <h3>Premium</h3>*/}
              {/*    <div className="price d-flex align-items-center justify-content-center">*/}
              {/*      <div className="price-figure">*/}
              {/*        <span className="price-number">€5</span>*/}
              {/*        <span className="price-tenure">per active user / per month</span>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*    <ul className="features list-unstyled">*/}
              {/*      <li>up to 35 employees</li>*/}
              {/*    </ul>*/}
              {/*    <div className="footer">*/}
              {/*      <a href={getSignUpLink(i18n.language)} className="btn btn-success">*/}
              {/*        Get Started Free*/}
              {/*      </a>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</li>*/}
              <li className="col-md-4">
                <div className="pricing-container">
                  <h3>Enterprise</h3>
                  <div className="price d-flex align-items-center justify-content-center">
                    <div className="price-figure">
                      <span className="price-number">{t("pricingPage.custom")}</span>
                      <span className="price-tenure">{t("pricingPage.customSolution")}</span>
                    </div>
                  </div>
                  <div className="footer">
                    <LocalizedLink to="/contact" className="btn btn-success">{t("pricingPage.contactUs")}</LocalizedLink>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </section>

        <section className="content bg-light position-relative">
          <div className="custom-shape-divider-top">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path
                  d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                  fill="#fff"></path>
            </svg>
          </div>
          <div className="container">
            <h2 className="content-title dark">{t("pricingPage.faq")}</h2>

            <ul className="list-group faq">
              <li className="list-group-item">
                <h5>{t("pricingPage.question_1")}</h5>
                <p>{t("pricingPage.answer_1")}</p>
              </li>
              <li className="list-group-item">
                <h5>{t("pricingPage.question_2")}</h5>
                <p>{t("pricingPage.answer_2")}</p>
              </li>
              <li className="list-group-item">
                <h5>{t("pricingPage.question_4")}</h5>
                <p>{t("pricingPage.answer_4")}</p>
              </li>
              <li className="list-group-item">
                <h5>{t("pricingPage.question_3")}</h5>
                <p>{t("pricingPage.answer_3")}</p>
              </li>
              <li className="list-group-item">
                <h5>{t("pricingPage.question_6")}</h5>
                <div>
                  <span dangerouslySetInnerHTML={{
                    __html: t("pricingPage.answer_6")
                  }} />
                </div>
              </li>
              <li className="list-group-item">
                <h5>{t("pricingPage.question_5")}</h5>
                <p>{t("pricingPage.answer_5")}</p>
              </li>
            </ul>
          </div>
        </section>
      </div>
    </>
  )
}

export default PricingPage
